<script setup lang="ts">
import { VueFinalModal, useModal } from 'vue-final-modal'
import type { Sector } from '@register'
import { leaseSectorReportOptions } from '~/constants/LeaseSector'
import {
  type ReportTemplateFieldDefinition,
  type ReportTemplate,
  Product,
} from '@reports'

const props = defineProps<{
  template: ReportTemplate
  divisionId: string
}>()

const emit = defineEmits<{
  download: [template: ReportTemplate]
  save: [templateId: string]
  view: []
  close: []
}>()

/* === SELECTED ===*/
const selectedFields = ref<ReportTemplateFieldDefinition[]>([
  ...(props.template.template?.fields ?? []),
])
const selectedFieldsIds = computed(() => selectedFields.value.map((f) => f.id))
const sector = ref<Sector | null>(props.template.sector ?? null)
const product = ref(props.template.product)
const productsOptions = [
  {
    label: 'AI Abstraction',
    value: Product.LEASE_REGISTER,
  },
  {
    label: 'Portfolio',
    value: Product.LEASE_MANAGER,
  },
]
const isCustom = computed(() => {
  if (isPending.value) return false
  if (props.template.id === 'custom') return true
  if (sector.value !== (props.template.sector ?? null)) return true
  return (
    JSON.stringify(selectedFieldsIds.value) !==
    JSON.stringify((props.template.template?.fields ?? []).map((f) => f.id))
  )
})
const resultTemplate = computed<ReportTemplate>(() => ({
  ...props.template,
  id: isCustom.value ? 'custom' : props.template.id,
  sector: sector.value ?? undefined,
  product: product.value,
  template: {
    ...props.template.template,
    fields: selectedFields.value,
  },
}))
/* === SELECTED ===*/

/* === FIELDS === */
const { data, isPending } = useReportFields(
  props.divisionId,
  computed(() => ({
    id: props.template.id,
    product: product.value,
  })),
)
const groups = computed(() => data.value ?? [])
/* === FIELDS === */

/* === SAVE === */
const { open: onSave, close } = useModal({
  component: getAsyncComponent('ReportsTemplateCustomSaveModal'),
  attrs: {
    divisionId: props.divisionId,
    // @ts-expect-error - Reactivity
    template: resultTemplate,
    onSave: (_templateId) => {
      close()
      emit('save', _templateId)
    },
    onCancel: () => close(),
    onClose: () => close(),
  },
})
/* === SAVE === */

/* === VIEW ===*/
const query = useRouteQuery()
const viewURI = computed(() => ({
  path: `/division/${props.divisionId}/reports/view`,
  query: {
    ...query.value,
    template: isCustom.value ? 'custom' : props.template.id!, // TODO: Should we use `custom` here?
    fieldIds: isCustom.value ? selectedFieldsIds.value : undefined,
    sector: sector.value,
    product: product.value,
  },
}))
/* === VIEW ===*/

/* === LISTENERS ===*/
const onDownload = () => emit('download', resultTemplate.value)
const onView = () => emit('view')
const onClose = () => emit('close')
/* === LISTENERS ===*/
</script>

<template>
  <VueFinalModal
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
    :content-transition="
      {
        'enter-active-class': 'transition duration-300 ease-in-out transform',
        'enter-from-class': '-translate-y-6 opacity-0',
        'enter-to-class': 'translate-y-0 opacity-100',
        'leave-active-class': 'transition duration-300 ease-in-out transform',
        'leave-to-class': 'translate-y-6 opacity-0',
        'leave-from-class': '-translate-y-0 opacity-100',
      } as any
    "
  >
    <ModalContainer
      title="Customise template"
      :close-on-click="false"
      container-class="max-w-[1000px] bg-gray-800 border-gray-750 dark:bg-gray-800 dark:border-gray-750"
      body-class="p-0"
      @close="onClose"
    >
      <div class="flex flex-col transition-all">
        <div class="flex w-full flex-col">
          <div class="flex gap-4 p-6">
            <FormKit
              v-model="sector"
              type="listbox"
              :options="leaseSectorReportOptions"
              outer-class="w-[300px]"
            >
              <template #prefix>
                <div
                  class="flex items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
                >
                  Sector
                </div>
              </template>
            </FormKit>
            <!-- Only show when creating a custom template from scratch -->
            <FormKit
              v-if="props.template.id === 'custom'"
              v-model="product"
              type="listbox"
              :options="productsOptions"
              outer-class="w-[300px]"
            >
              <template #prefix>
                <div
                  class="flex items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
                >
                  Fields
                </div>
              </template>
            </FormKit>
          </div>

          <ReportsTransferList
            :key="product"
            v-model="selectedFields"
            :groups="groups"
          />

          <div class="flex justify-end gap-2 p-6">
            <Button
              v-if="isCustom"
              color="secondary"
              class="flex justify-center self-end whitespace-nowrap"
              @click="onSave"
            >
              Save as template
            </Button>
            <Button
              color="transparentSecondary"
              class="flex justify-center self-end whitespace-nowrap"
              @click="onDownload"
            >
              Download report
            </Button>
            <Button
              :to="viewURI"
              color="primary"
              class="flex justify-center self-end whitespace-nowrap"
              @click="onView"
            >
              View report
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  </VueFinalModal>
</template>
