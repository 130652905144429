import type { MaybeRefOrGetter } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import type { ReportTemplate, ReportFieldGroup, Product } from '@reports'

const fetchTemplateFields = (divisionId: string, templateId: string) =>
  fetchAPI<ReportFieldGroup[]>(
    `/companies/${divisionId}/reports/templates/${templateId}/fields`,
  )

const fetchProductFields = (divisionId: string, product: Product) =>
  fetchAPI<ReportFieldGroup[]>(
    `/companies/${divisionId}/reports/products/${product}/fields`,
  )

export const useReportFields = (
  divisionId: string,
  template: MaybeRefOrGetter<Pick<ReportTemplate, 'id' | 'product'>>,
) => {
  return useQuery({
    queryKey: ['division', divisionId, 'reports', 'fields', template],
    queryFn: () => {
      const _template = toValue(template)
      const isCustomTemplate = _template.id === 'custom'

      if (isCustomTemplate) {
        if (!_template.product)
          throw new Error('Product is required for custom template')

        return fetchProductFields(divisionId, _template.product)
      }

      if (!_template.id) throw new Error('Template ID is required')
      return fetchTemplateFields(divisionId, _template.id)
    },
  })
}
